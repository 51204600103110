<template>
  <article>
    <div class="section activity-section">
      <div v-if="isStarted">
        <div v-if="isResting"><h2>Repos</h2></div>
        <div v-else>
          <h3>{{ currentCategory?.name }}</h3>
          <span
            >Round {{ currentIteration + 1 }} / {{ currentCategory?.loop }} -
            Exercice {{ currentExercise + 1 }} /
            {{ currentCategory?.exerciseCount }}</span
          >
        </div>
        <h1
          v-if="isResting"
          class="timer"
          :class="remainingTime < 4 ? 'is-danger' : ''"
        >
          {{ remainingTime }}
        </h1>
        <div v-if="!isResting" class="columns current-exercise vertical-center">
          <div class="column">
            <h1 class="timer" :class="remainingTime < 4 ? 'is-danger' : ''">
              {{ remainingTime }}
            </h1>
            <h2>{{ exercises?.[currentCategory?.name]?.[currentExercise] }}</h2>
          </div>
          <div class="column">
            <img
              class="exercise-image"
              :src="
                getExerciseImagePath(
                  currentCategory?.name,
                  exercises?.[currentCategory?.name]?.[currentExercise]
                )
              "
              alt="Image de l'exercice"
            />
          </div>
        </div>
        <h3 v-if="currentExercise < currentCategory.exerciseCount - 1">
          Exercice suivant :
          {{ exercises?.[currentCategory?.name]?.[currentExercise + 1] }}
        </h3>
      </div>

      <div class="activity-buttons">
        <button
          v-if="!this.timerInterval"
          @click="onStartTimer"
          :class="!isStarted ? 'start-button' : ''"
        >
          Go
        </button>
        <button v-else @click="onPauseTimer">Pause</button>
        <button v-if="isStarted" @click="onStopActivity" class="stop-button">
          Stop
        </button>
      </div>
    </div>

    <div class="section program-section">
      <h2>
        Workout Of the Day
        <a v-if="!isStarted" @click="generateExercises()">⟳</a>
      </h2>
      <div v-for="category in program" :key="category.name">
        <h3 :class="{ 'is-primary': isCurrentCategory(category) }">
          {{ category.name }}
        </h3>
        <ul>
          <li v-for="exercice in exercises?.[category.name]" :key="exercice">
            <span
              :class="{ 'is-primary': isCurrentExercise(exercice) }"
              :style="{
                fontWeight: isCurrentExercise(exercice) ? 'bold' : 'normal',
              }"
            >
              {{ exercice }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </article>
</template>

<script>
//import exos json
import exosJson from "./assets/exos.json";
import programJson from "./assets/programme.json";
import countdownMp3 from "./assets/countdown.mp3";

export default {
  name: "App",
  components: {},
  data() {
    return {
      isStarted: false,

      remainingTime: 0,
      currentCategory: null,
      currentIteration: 0,
      currentExercise: 0,
      isResting: false,

      timerInterval: null,

      program: [],
      exercises: {},

      intialSeed: 1,
      seed: 1,
    };
  },

  methods: {
    pseudoRandom() {
      const x = Math.sin(this.seed++) * 10000;
      return x - Math.floor(x);
    },

    onStartTimer() {
      if (!this.isStarted) {
        this.startActivity();
      }
      this.resumeTimer();
    },

    startActivity() {
      this.currentCategory = this.program[0];
      this.currentIteration = 0;
      this.currentExercise = 0;
      this.isResting = false;
      this.remainingTime = this.currentCategory.exerciseDuration;
      this.isStarted = true;
    },

    resumeTimer() {
      this.timerInterval = setInterval(this.tick, 1000);
    },

    onPauseTimer() {
      clearInterval(this.timerInterval);
      this.timerInterval = null;
    },

    onStopActivity() {
      if (window.confirm("Êtes-vous sûr de vouloir arrêter l'activité ?")) {
        this.onPauseTimer();
        this.isStarted = false;
        this.clearCurrentStateFromLocalStorage();
      }
    },

    saveCurrentStateInLocalStorage() {
      localStorage.setItem(
        "currentCategory",
        JSON.stringify(this.currentCategory)
      );
      localStorage.setItem("currentIteration", this.currentIteration);
      localStorage.setItem("currentExercise", this.currentExercise);
      localStorage.setItem("isResting", this.isResting);
    },

    loadCurrentStateFromLocalStorage() {
      this.currentCategory =
        JSON.parse(localStorage.getItem("currentCategory")) || null;
      if (this.currentCategory) {
        this.currentIteration =
          Number(localStorage.getItem("currentIteration")) ?? 0;
        this.currentExercise =
          Number(localStorage.getItem("currentExercise")) ?? 0;
        this.isResting =
          Boolean(localStorage.getItem("isResting") == "true") ?? false;
        if (this.isResting) {
          if (this.currentExercise === this.currentCategory.exerciseCount - 1) {
            this.remainingTime = this.currentCategory.loopRest;
          } else {
            this.remainingTime = this.currentCategory.exerciseRest;
          }
        } else {
          this.remainingTime = this.currentCategory.exerciseDuration;
        }
        this.isStarted = true;
      }
    },

    clearCurrentStateFromLocalStorage() {
      localStorage.removeItem("currentCategory");
      localStorage.removeItem("currentIteration");
      localStorage.removeItem("currentExercise");
      localStorage.removeItem("isResting");
    },

    setQueryStringParameter(key, value) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(key, value);
      window.history.replaceState(
        {},
        "",
        `${location.pathname}?${searchParams}`
      );
    },

    getQueryStringParameter(key) {
      const searchParams = new URLSearchParams(window.location.search);
      return searchParams.get(key);
    },

    removeQueryStringParameter(key) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete(key);
      window.history.replaceState(
        {},
        "",
        `${location.pathname}?${searchParams}`
      );
    },

    generateExercises() {
      this.seed = this.intialSeed;
      this.setQueryStringParameter("seed", this.intialSeed);

      this.exercises = {};
      for (const category of programJson) {
        this.exercises[category.name] = [];
        const exos = category.notRandom
          ? exosJson[category.name]
          : exosJson[category.name].sort(() => this.pseudoRandom() - 0.5);
        for (let i = 0; i < category.exerciseCount; i++) {
          this.exercises[category.name].push(exos[i % exos.length]);
        }
      }
      localStorage.setItem("exercises", JSON.stringify(this.exercises));
      this.intialSeed = Math.floor(Math.random() * 1000000);
    },

    tick() {
      if (this.remainingTime === 4) {
        const audio = new Audio(countdownMp3);
        audio.play();
      }

      if (this.remainingTime > 0) {
        this.remainingTime--;
      } else {
        if (this.isResting) {
          this.currentExercise++;
          if (this.currentExercise >= this.currentCategory.exerciseCount) {
            this.currentExercise = 0;
            this.currentIteration++;
            if (this.currentIteration >= this.currentCategory.loop) {
              this.currentIteration = 0;
              this.currentCategory =
                this.program[this.program.indexOf(this.currentCategory) + 1];
              if (!this.currentCategory) {
                this.currentCategory = null;
                this.onPauseTimer();
                this.isStarted = false;
                this.clearCurrentStateFromLocalStorage();
              }
            }
          }
          this.remainingTime = this.currentCategory.exerciseDuration;
        } else {
          if (this.currentExercise === this.currentCategory.exerciseCount - 1) {
            this.remainingTime = this.currentCategory.loopRest;
          } else {
            this.remainingTime = this.currentCategory.exerciseRest;
          }
        }
        this.isResting = !this.isResting;
        this.saveCurrentStateInLocalStorage();
        if (this.remainingTime === 0) this.tick();
      }
    },

    isCurrentCategory(category) {
      return this.currentCategory?.name === category.name;
    },

    isCurrentExercise(exercise) {
      return (
        !this.isResting &&
        this.currentCategory &&
        this.exercises[this.currentCategory?.name][this.currentExercise] ===
          exercise
      );
    },

    getExerciseImagePath(categoryName, exerciseName) {
      const convertedExerciseName = exerciseName
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/\s+/g, "_")
        .replace(/[^\w_]/g, "");
      return `/img/${categoryName}/${convertedExerciseName}.png`;
    },
  },

  mounted() {
    this.program = programJson;
    if (this.getQueryStringParameter("seed")) {
      this.intialSeed = Number(this.getQueryStringParameter("seed"));
    } else {
      this.intialSeed = Math.floor(Math.random() * 1000000);
    }
    const currentCategory = localStorage.getItem("currentCategory");
    const storedExercises = localStorage.getItem("exercises");
    if (currentCategory && storedExercises) {
      this.exercises = JSON.parse(storedExercises);
      this.loadCurrentStateFromLocalStorage();
      if (this.getQueryStringParameter("seed"))
        this.removeQueryStringParameter("seed");
      setTimeout(() => {
        window.alert(
          "Votre activité a été restaurée, vous pouvez reprendre ! Pour l'arrêter, cliquez sur le bouton 'Stop'"
        );
      }, 500);
    } else {
      this.generateExercises();
    }
  },

  unmounted() {
    console.log("Unmounted");
    this.onPauseTimer();
  },
};
</script>

<style>
@media screen and (max-width: 600px) {
  article {
    flex-direction: column !important;
    max-width: 90% !important;
  }
}

article {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: sans-serif;
  gap: 10rem;
  max-width: 80rem;
  margin: 3rem auto;
}

.timer {
  font-size: 5em;
}

.section {
  display: flex;
  flex-direction: column;
}

.program-section {
  right: 0;
  width: 40%;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  margin-left: auto;
  text-align: left;
}

.program-section h2,
.program-section h3 {
  text-align: center;
}

.activity-section {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 60%;
  text-align: center;
  align-items: center;
}

button {
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 3px solid black;
  background-color: white;
  font-size: 2rem;
  font-weight: 600;
  cursor: pointer;
  min-width: 15rem;
  min-height: 5rem;
}

.start-button {
  width: 15rem;
  height: 15rem;
  border-radius: 25%;
  font-size: 5rem;
  font-weight: 500;
  border: 5px solid green;
  background-color: greenyellow;
  min-width: 0;
}

.stop-button {
  background-color: red;
  border-color: red;
  color: white;
  min-width: 7.5rem;
}

.activity-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

button:hover {
  background-color: #eee;
}

.stop-button:hover {
  background-color: rgb(255, 108, 108);
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

.is-danger {
  color: red;
}

.is-primary {
  color: #13a0dd;
}

.columns {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.column {
  flex: 1;
  justify-content: center;
  align-content: center;
}

.exercise-image {
  max-width: 30rem;
  max-height: 20rem;
  margin: 2rem;
  margin-right: 3rem;
}

.current-exercise {
  /*border: 0px solid black;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);*/
  margin: 1rem;
  padding: 2rem;
}
</style>
